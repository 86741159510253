<template>
  <div class="dealOrder">
    <div class="dealOrderTitle">
      <div class="myDealOrder">
        <p>我的持仓</p>
      </div>
    </div>
    <el-table :data="tableData" border>
      <el-table-column prop="name" label="商品分类"></el-table-column>
      <el-table-column prop="openPrice" label="建仓价格"></el-table-column>
      <el-table-column prop="holdPrice" label="持仓价格"></el-table-column>
      <el-table-column prop="openNumber" label="建仓数量"></el-table-column>
      <el-table-column prop="holdNumber" label="持仓数量"></el-table-column>
      <el-table-column prop="frozenNumber" label="冻结数量"></el-table-column>
      <el-table-column prop="meterageUnit" label="计量单位"></el-table-column>
      <el-table-column prop="openTimeFormat" label="建仓时间"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="viewDetails(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog v-dialogDrag title="持仓详情" :close-on-click-modal="false" :visible.sync="dialogVisible" width="670px">
      <div class="orderDetails">
        <el-row>
          <el-col :span="4">
            <span class="listTitle">商品分类：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.name }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">建仓价格：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.openPrice }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">持仓价格：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.holdPrice }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">建仓数量：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.openNumber }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">持仓数量：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.holdNumber }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">冻结数量：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.frozenNumber }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">计量单位：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.meterageUnit }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">建仓时间：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.openTimeFormat }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">仓库：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.warehouse }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">区域：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.origin }}1</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">公司名称：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.enterpriseName }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">银行名称：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.bankName }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <span class="listTitle">银行卡号：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.bankNumber }}</span>
          </el-col>
          <el-col :span="4">
            <span class="listTitle">备注：</span>
          </el-col>
          <el-col :span="8">
            <span class="listContent">{{ orderDetails.note }}</span>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 10,
      totalPage: null,
      orderDetails: {},
      dialogVisible: false
    };
  },
  mounted() {
    this.getDealDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDealDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDealDatas();
    },
    getDealDatas() {
      protocolFwd.param_queryHoldNote.param.page = this.current - 1;
      protocolFwd.param_queryHoldNote.param.size = this.pageSize;
      
      http.postFront(protocolFwd.param_queryHoldNote).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          const datas = value.content;
          for (let i = 0; i < datas.length; i++) {
            if (datas[i].openTime) {
              datas[i].openTimeFormat = global.util.DateFormate(
                datas[i].openTime
              );
            } else {
              datas[i].openTimeFormat = "--";
            }
          }
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    viewDetails(item) {
      this.orderDetails = item;
      this.dialogVisible = true;
    }
  }
};
</script>
<style scoped>
.dealOrder {
  padding: 0 15px;
}
.dealOrderTitle {
  overflow: hidden;
}
.dealOrder .myDealOrder {
  float: left;
  line-height: 40px;
}
.dealOrderTitle .myDealOrder p {
  font-size: 16px;
}
.orderDetails {
  line-height: 35px;
}
.orderDetails .listTitle {
  display: block;
  text-align: right;
}
.orderDetails .listContent {
  margin-left: 5px;
}
.dealOrder .pagination {
  margin-top: 15px;
}
</style>

import { render, staticRenderFns } from "./holdProduct.vue?vue&type=template&id=0514e336&scoped=true"
import script from "./holdProduct.vue?vue&type=script&lang=js"
export * from "./holdProduct.vue?vue&type=script&lang=js"
import style0 from "./holdProduct.vue?vue&type=style&index=0&id=0514e336&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0514e336",
  null
  
)

export default component.exports